<script>

import Logo from '@/components/Logo.vue'
export default {
    components: {Logo},
    created() {  
    },
    data() {
        return { 
           availableExamples: [
            { url: 'jamii-batklubb', btn: 'Jamii Båtklubb' }, 
            { url: 'jamii-yoga', btn: 'Jamii Yoga' }, 
            { url: 'jamii-kontorshotell', btn: 'Jamii Kontorshotell' }, 
            { url: 'jamii-samfallighetsforening', btn: 'Jamii Samfällighetsförening' }
        ]
        }
    },   
    methods:
    {
        goTopage(url)
        {
              window.location.href = url;
        },
        blurOut(example)
        {
            if(this.availableExamples.filter(x => x.hovered) == 0) {
                return false;
            }
            else if(example.hovered) {
                return false;
            }


            return true;
        }   
    }
}
</script>
<template>
    <div style="overflow: auto;" :style="{'min-height': this.$windowHeight-200 + 'px'}">
        <section class="SaleNews" v-fade-in>
            <p style="margin-top: 100px;" class="text-center">Exempelsidor som du kan göra till din förening med hjälp av våran app.</p>
            <div class="BookingList-wrapper">
                    <div v-for="(example, index) in availableExamples" class="BookingList-booking-item"> 
                        <div @mouseover="example.hovered = true" @mouseout="example.hovered = false" :class="blurOut(example) ? 'ImageGallery-grayscale' : 'ImageGallery-no-grayscale'" >
                            <div class="position-relative">
                                <div class="BookingItem-img-area">
                                    <img :src="require('@/assets/imgs/examples/'+ index+'.png')" />
                                </div>
                            </div>
                            <div class="BookingItem-max-booking">
                                <a class="Bookings-btn float-right margin-top-15" :href="'https://www.jamii.se/' + example.url" target="_blank">Gå till {{example.btn}}</a>
                            </div>
                        </div>              
                    </div>
                </div>
        </section>
    </div>
</template>
<style scoped>

.Bookings-btn{
        background-color: rgb(181, 205, 191);
    padding: 10px 20px;
    font-size: 21px;
    color: black;
    border: 0px solid lightgray;
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    cursor: pointer;
    }
    
    .Bookings-btn:hover {
        filter: brightness(0.8);
    }

.BookingList-wrapper
    {
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .BookingList-booking-item
    {
        padding:10px; 
        margin: 20px;
        background: white;
        box-shadow: 1px 1px #ddd;
    }

    .BookingItem-img-area
    {
        height: 340px; 
        overflow: hidden;
        text-align: center;
        display: flex;
        align-items: flex-end;
    }

    .BookingItem-max-booking
    {
        margin: 30px 10px 10px 10px;
        overflow:auto;
    }

    .BookingItem-wrapper
    {
        display:flex;
        flex-wrap: wrap;
        

    }

    .BookingItem-img-area i
    {
        display:block;
        padding-top: 40px;
        font-size: 60px;
        background-color:aliceblue;
        height: 100vh;
    }

    img
    {
        height: 100%;
    }

    h4{
        margin-bottom:0px;
        padding:10px;
        width: 50%;
        position: absolute;
        bottom: -20px;
        left: 10px;
    }

    
.ImageGallery-grayscale {
    filter:grayscale(80%);
    transition: ease-out 0.2s;
}

.ImageGallery-no-grayscale {
    filter:grayscale(0%);
    transition: ease-out 0.2s;
}

    @media only screen and (max-width: 1000px) {
        .BookingItem-img-area {
            height: auto;
            width: auto;
        }
        
        .BookingList-booking-item
        {
            padding:10px; 
            margin: 30px;
            width: calc(100% - 80px);
        }    
        
        img
    {
        height: auto;
        width: 100%;
    }
    }


</style>