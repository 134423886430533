<script>
import config from "@/helpers/config"
import niceFunctions from "@/helpers/niceFunctions"
import SvgTransition from '@/components/SvgTransition.vue'
export default {
    components: { SvgTransition },
    created() {  
        this.$cache.createCache('publicPage', 'public-page/', {id: this.$route.params.id });      
       
    },
    computed: {
        url() { return config.urlToServer },  
        publicPageColors() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageColors : null },    
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        boardMembers() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.boardMembers : {} },
        workGroups() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.workGroups : {} },
        statutes() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.statutes : {} },
        rulesOfProcedure() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.rulesOfProcedure : {} },
        publicPageCommunity() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageCommunity : {} },   
        publicPageImages() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageImages : null },
        height() { return window.innerHeight/1.3 + 'px' },
    },
    methods:
    {
        getColor(location)
        {
            return niceFunctions.getColor(this.publicPageColors, location)
        }  
    }
}
</script>
<template>
   <section class="public-page" v-if="publicPage != null" v-fade-in>
        <div class="top-public position-relative" :style="{ 'height': height }" v-background="publicPageImages.find(x => x.location == 'community')">
            <SvgTransition :publicPage="publicPage"></SvgTransition>   
        </div>
        <div class="text-section" v-html="this.$adaptHtml.adaptHtml(publicPageCommunity.community_text, getColor('header_color_on_white_bg'))"></div>
       <div class="text-section">
            <div class="Community-table" v-if="publicPageCommunity.show_board_members" v-fade-in>
                <h2 :style="{ 'color': getColor('header_color_on_white_bg') }">Styrelsen</h2>
                <table>
                    <tr v-for="member in boardMembers" class="table-style">                       
                    <td> {{member.board_member_type}}</td>
                    <td> {{member.user_name}} ({{ member.election_period }})</td>
                    </tr>
                </table>
            </div>
            
            <div class="Community-table" v-if="publicPageCommunity.show_work_groups" v-fade-in>
                <h2 :style="{ 'color':getColor('header_color_on_white_bg') }">Arbetsgrupper</h2>
                <table>
                    <tr v-for="group in workGroups" class="table-style">                       
                    <td> {{ group.name }}</td>
                    <td> Ansvariga: {{ group.members.map(x => x.user.full_name).join(", ") }}</td>
                    </tr>
                </table>
            </div>
            
            <div class="Community-table" v-if="publicPageCommunity.show_statutes" v-fade-in>
                <h2 :style="{ 'color': getColor('header_color_on_white_bg') }">Stadgar</h2>              
                <ul style="padding-left: 20px;">
                    <li v-for="statute in statutes" class="table-style">                       
                        <span v-html="statute.statute"></span>                          
                    </li>
                </ul>
                
            </div>
            
            <div class="Community-table" v-if="publicPageCommunity.show_rules_of_procedure" v-fade-in>
                <h2 :style="{ 'color': getColor('header_color_on_white_bg') }">Ordningsregler</h2>
                <ul style="padding-left: 20px;">
                    <li v-for="rule_of_procedure in rulesOfProcedure" class="table-style">                       
                        <span v-html="rule_of_procedure.rule_of_procedure"></span>                          
                    </li>
                </ul>
            </div>
       </div>
   </section>
</template>
<style scoped>
    .Community-table
    {
        max-width: 1300px;
        margin: auto;
        margin-bottom: 60px;
    }

    p{
        width: 8px;
        height: 8px;
        border-radius: 4px;
        margin: 5px 7px 0px 0px;
        float: left;
    }
</style>