<script>
import config from "@/helpers/config"
import niceFunctions from "@/helpers/niceFunctions"
import SvgTransition from '@/components/SvgTransition.vue'
export default {
    components: { SvgTransition },
    created() {  
        this.$cache.createCache('publicPage', 'public-page/', {id: this.$route.params.id });      
       
    },
    computed: {
        url() { return config.urlToServer },  
        publicPageColors() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageColors : null },
        tabs() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.tabs : null },   
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        height() { return window.innerHeight/1.3 + 'px' },
        tab() { return this.tabs.find(x => x.id == this.$route.params.tabid) },        
        publicPageImages() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageImages : null },
    },
    methods:
    {
        getColor(location)
        {
            return niceFunctions.getColor(this.publicPageColors, location)
        }  
    }
}
</script>
<template>
   <section v-if="tabs != null && tab" v-fade-in>
        <div class="top-public position-relative" :style="{'height': height }" v-background="publicPageImages.find(x => x.location == 'tab_' + tab.id)">
            <SvgTransition :publicPage="publicPage"></SvgTransition>   
        </div>
        <div>
            <div class="text-section">
                <h1 :style="{ 'color': getColor('header_color_on_white_bg') }">{{ tab.name }}</h1>
                <div v-html="this.$adaptHtml.adaptHtml(tab.text, getColor('header_color'))"></div>
            </div>
        </div>
   </section>
</template>
<style scoped>
    section {
        color: black;
    }
</style>