<script>
import config from "@/helpers/config"
import niceFunctions from "@/helpers/niceFunctions"
import SvgTransition from '@/components/SvgTransition.vue'
export default {
    components: { SvgTransition },
    created() {  
        this.$cache.createCache('publicPage', 'public-page/', {id: this.$route.params.id });      
       
    },
    computed: {
        url() { return config.urlToServer },  
        publicPageColors() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageColors : null },
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        height() { return window.innerHeight/1.3 + 'px' },
        availableBookings() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.availableBookings : {} },
        settings() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.settings : null },
        hasSettings() { return this.settings },
    },
    methods:
    {
        goTopage(bookingItem)
        {
            $(window).scrollTop(0);  
            var page = bookingItem.duration_unit == 'Dagar' ?  'bookingDaysAdd' : 'bookingHoursAdd';          
            this.$router.push({ name: page, params: { id: this.$route.params.id, bookingid: bookingItem.id } })
        } ,
        blurOut(booking)
        {
            if(this.availableBookings.filter(x => x.hovered) == 0) {
                return false;
            }
            else if(booking.hovered) {
                return false;
            }
            return true;
        },
        getColor(location)
        {
            return niceFunctions.getColor(this.publicPageColors, location)
        }     
    }
}
</script>
<template>
   <section  class="public-page" v-if="publicPage != null">   
        <div class="top-public position-relative" :style="{ 'height': height, 'background-image': 'url(' + url + 'uploads/'+ publicPage.customer_id+'/images/'+availableBookings[0].image_name + ')' }">
            <SvgTransition :publicPage="publicPage"></SvgTransition>   
        </div>
        <div class="text-section" style="padding-top: 70px;">
                <h1 :style="{ 'color': getColor('header_color_on_white_bg') }" class="margin-top-25px">{{ hasSettings ? settings.BookingItem.plural : '' }}</h1>  
                <div class="BookingList-wrapper">
                    <div v-for="booking in availableBookings" class="BookingList-booking-item"> 
                        <div @mouseover="booking.hovered = true" @mouseout="booking.hovered = false" :class="blurOut(booking) ? 'ImageGallery-grayscale' : 'ImageGallery-no-grayscale'" >
                            <div class="position-relative">
                                <div class="BookingItem-img-area">
                                    <img v-if="booking.image_id" :src="url + 'uploads/'+ publicPage.customer_id+'/images/thumbnails/'+ booking.image_name" />  
                                    <i v-else class="fa-regular fa-image wow-color wow-color-bg"></i>
                                </div>
                                <h4 :style="{'background-color': getColor('background_color') }">{{ booking.name }}</h4>
                            </div>
                            <div class="BookingItem-max-booking">
                                <p>Maxlängd på bokning: {{ booking.duration_time }} {{ booking.duration_unit }}</p>
                                <button class="Bookings-btn float-right margin-top-15" :style="{'background-color': getColor('background_color') }" @click="goTopage(booking)">Boka</button>
                            </div>
                        </div>              
                    </div>
                </div>
            </div>
   </section>
</template>
<style scoped>
    .Bookings-btn{
        background-color: rgb(181, 205, 191);
    padding: 10px 20px;
    font-size: 21px;
    color: black;
    border: 0px solid lightgray;
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    cursor: pointer;
    }
    
    .Bookings-btn:hover {
        filter: brightness(0.8);
    }

.BookingList-wrapper
    {
        display:flex;
        flex-wrap: wrap;
    }
    .BookingList-booking-item
    {
        padding:10px; 
        margin: 20px;
        background: white;
        width: calc(33% - 60px);
        box-shadow: 1px 1px #ddd;
    }

    .BookingItem-img-area
    {
        height: 250px; 
        overflow: hidden;
        text-align: center;
        display: flex;
        align-items: flex-end;
    }

    .BookingItem-max-booking
    {
        margin: 30px 10px 10px 10px;
        overflow:auto;
    }

    .BookingItem-wrapper
    {
        display:flex;
        flex-wrap: wrap;

    }

    .BookingItem-img-area i
    {
        display:block;
        padding-top: 40px;
        font-size: 60px;
        background-color:aliceblue;
        height: 100vh;
    }

    img
    {
        width: 100%;
    }

    h4{
        margin-bottom:0px;
        padding:10px;
        width: 50%;
        position: absolute;
        bottom: -20px;
        left: 10px;
    }

    
.ImageGallery-grayscale {
    filter:grayscale(80%);
    transition: ease-out 0.2s;
}

.ImageGallery-no-grayscale {
    filter:grayscale(0%);
    transition: ease-out 0.2s;
}

    @media only screen and (max-width: 1000px) {
        .BookingItem-img-area {
            height: auto;
            width: auto;
        }
        
        .BookingList-booking-item
        {
            padding:10px; 
            margin: 30px;
            width: calc(100% - 80px);
        }       
    }
</style>