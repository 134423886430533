<script>
import PopUp from '@/components/PopUp.vue'
import Switch from '@/components/Switch.vue'
import message from '@/helpers/message'
import config from "@/helpers/config"
import niceFunctions from "@/helpers/niceFunctions"
import SvgTransition from '@/components/SvgTransition.vue'

export default {
    components: { PopUp, Switch, SvgTransition },
    props: ['windowScroll'],
    data(){
        return {
            month: '',
            currentDate: null,
            noOfDays: null,
            today: new Date(),
            months: this.$isMobile ? 
                ['jan', 'feb', 'mars', 'april', 'maj', 'juni', 'juli', 'aug','sept','okt', 'nov', 'dec']
                : ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti','september','oktober', 'november', 'december'],
            days: ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön'],
            daysInNumbers: [6,0,1,2,3,4,5],
            allDaysInMonth: [] ,
            allBookingTimes: null,           
            bookingItem: null,
            hours: [],
            bookedTimes: [],
            newBookingTime: { 
                id: 0, 
                selected_date: null, 
                start_time: null, 
                end_time: null, 
                start_date: null, 
                end_date: null, 
                comments: '', 
                booking_item_id: null,
                duration_time: null,
                duration_unit: null,
                email: '',
                mobileNo: '',
                send_reminder_to_mobile_no: false,
                agree_to_terms: false,
                full_name: '',
                customer_name: '',
                booking_item_name: ''
             },
            indexOfStart: null,
            indexOfEnd: null,
            showSummary: false,
            showConfirmation: false,
            isSending: false,
            showBookingBar: false
        }
    },
    created()
    {
        this.getBooking();
        this.$cache.createCache('publicPage', 'public-page/', { id: this.$route.params.id }); 
    },
    methods:
    {
        getBooking()
        {
            var self = this;     
            this.currentDate = new Date();
            this.$communicator.get('add-public-booking/' + this.$route.params.bookingid)
                .then(function(data)
                {             
                    self.bookingItem = data.booking;
                    self.allBookingTimes = data.allBookings;
                    self.renderCalenderMonth(self.currentDate);
                })
                .fail(function(error) {   
                    
                });   
        },
        closeConfirm()
        {
            this.$router.push({ name: 'home', params: { id: this.$route.params.id } });
        },
        showPrevMonth()
        {
            this.emptyBookingValues();
            this.currentDate.setMonth(this.currentDate.getMonth()-1);
            this.renderCalenderMonth(this.currentDate);
        },
        showNextMonth()
        {
            this.emptyBookingValues();
            this.currentDate.setMonth(this.currentDate.getMonth()+1);
            this.renderCalenderMonth(this.currentDate);
        },
        renderCalenderMonth(date)
        {
            this.month = this.months[date.getMonth()];
            this.noOfDays = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();         
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);            
            var dayInMonth = 1;
            var dayInNextMonth = 1;
            
            //fill calender with days
            this.allDaysInMonth = [];
            for(var i = 0; i <= 41; i++)
            {
                if(i >= this.daysInNumbers[firstDay.getDay()] && dayInMonth <= this.noOfDays)
                {
                   //calculate day date and if its sunday and if its today
                    var dayDate = new Date(date.getFullYear(), date.getMonth(), dayInMonth);
                    
                    var isToday = this.today.getFullYear() == dayDate.getFullYear() && this.today.getMonth() == dayDate.getMonth() && this.today.getDate() == dayDate.getDate();
                    this.allDaysInMonth.push({ date: dayDate, dateString: this.getDateString(dayDate), day: dayInMonth++, isCurrentMonth: true, isSunday: dayDate.getDay() == 0, isToday: isToday });
                }
                else if(i < this.daysInNumbers[firstDay.getDay()])
                {
                    this.allDaysInMonth.push({day: 'prevMonth', isCurrentMonth: false, isSunday: false, isToday: false });
                    
                } else{
                    this.allDaysInMonth.push({ day: dayInNextMonth++, isCurrentMonth: false, isSunday: false, isToday: false });                    
                }
            }

            //fill previous months visible days
            var noOfDaysPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
            for(var i = 41; i >= 0; i--)
            {
                if(this.allDaysInMonth[i].day  == 'prevMonth')
                {
                    this.allDaysInMonth[i].day = noOfDaysPrevMonth--;
                }
            }      
        },
        getDateString(date)
        {
            return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
        },
        selectDate(date)
        { 
            if(!date.isCurrentMonth)
            {
                return;
            }   

            //highlight day in calendar    
            this.allDaysInMonth.forEach(function(date) {
                date.selected = false;
            });                   
            date.selected = true;

            this.newBookingTime.selected_date = date.dateString;
            this.emptyBookingValues();

            this.bookedTimes = this.allBookingTimes.filter(x => date.date != undefined && x.start_date.split('T')[0] == date.dateString);
            var occupiedHours = [];
            this.bookedTimes.forEach(function(bookedTime) {
                bookedTime.timespan.forEach(function(hour) {
                    occupiedHours.push(hour);
                });
            });

            this.hours = [];
            var hitStartDate = false;
            var hitEndDate = false;
            var lastHour = false;
            for(var i = 0; i <= 24; i++)
            {
                //check if hour is earlier than booking item start time
                if(i == parseInt(this.bookingItem.day_available_start_time.substr(0, 2)))
                {
                    hitStartDate = true;
                }
                                
                //check if hour is later than booking item end time
                if(i == parseInt(this.bookingItem.day_available_end_time.substr(0, 2))+1)
                {
                    hitEndDate = true;
                }

                //make last hour not selectable since it is out of range
                if(i == parseInt(this.bookingItem.day_available_end_time.substr(0, 2)))
                {
                    lastHour = true;
                }
                               
                if(!hitStartDate || hitEndDate)
                {
                    continue;
                }

                var hour = i < 10 ? '0' + i + ':00:00' : i + ':00:00'
                var booking = this.bookedTimes.find(x => x.start_date.split('T')[1] == hour);
                this.hours.push({ id: i, hour: i < 10 ? '0' + i + ':00' : i + ':00', booking: booking, selectable: !occupiedHours.some(x => x == i) && !lastHour, isStartTime: false });
            }

            setTimeout(() => {
                $("html, body").animate({ scrollTop: $("#BookingHoursAdd-times").position().top });
            }, 100);
        },
        emptyBookingValues()
        {
            this.newBookingTime.start_time = null;
            this.newBookingTime.end_time = null;
            this.newBookingTime.start_date = null;
            this.newBookingTime.end_date = null;
            this.indexOfStart = null,
            this.indexOfEnd = null;
            this.start_time = null;
        },
        selectTime(hour)
        {  
            message.removeMessage();         
            if(!hour.selectable)
            {
                return;
            }

            if(this.newBookingTime.start_time == null) {          
                this.newBookingTime.start_time = hour.hour;
                this.indexOfStart = this.hours.indexOf(hour);
                hour.isStartTime = true;
                hour.selected = true;
            }
            else if(hour.id - this.hours[this.indexOfStart].id >= this.bookingItem.duration_time)
            {
                message.setInfoMessage("Du har valt en längre bokningstid än vad som är möjligt. Maximal bokningstid är " + this.bookingItem.duration_time + " " + this.bookingItem.duration_unit);
                return;
            }
            else if(hour.id - this.hours[this.indexOfStart].id < 0)
            {
                message.setInfoMessage("Du har valt en sluttid som är tidigare än starttiden.");
                return;
            }
            else {
                
                this.indexOfEnd = this.hours.indexOf(hour);
                var timeOverAnotherBooking = false;
                for(var i = this.indexOfStart; i <= this.indexOfEnd; i++)
                {
                    if(!this.hours[i].selectable)
                    {
                        timeOverAnotherBooking = true;
                    } 
                }

                if(timeOverAnotherBooking)
                {
                    message.setInfoMessage("Du har valt en tid som ligger över en annan bokning. Vänligen ändra tid.");
                    return;
                }

                this.newBookingTime.end_time = this.hours[this.indexOfEnd+1].hour;
               
                this.hours.forEach(function(hour) {
                    hour.selected = false;                   
                });

                for(var i = this.indexOfStart; i <= this.indexOfEnd; i++)
                {
                    this.hours[i].selected = true;
                }
            }
        },
        save()
        {
            
            message.removeMessage();
            this.newBookingTime.booking_item_id = this.bookingItem.id;
            this.newBookingTime.start_date = this.newBookingTime.selected_date + ' ' + this.newBookingTime.start_time;
            this.newBookingTime.end_date = this.newBookingTime.selected_date + ' ' + this.newBookingTime.end_time;
            this.newBookingTime.duration_unit = this.bookingItem.duration_unit;
            this.newBookingTime.duration_time = this.indexOfEnd + 1 - this.indexOfStart;
            this.newBookingTime.customer_name = this.customerName;
            this.newBookingTime.booking_item_name = this.bookingItem.name;
            this.newBookingTime.customer_url_name = this.$route.params.id;

            var validationErrors = [];
                   
            if(!this.newBookingTime.full_name == null || this.newBookingTime.full_name == '')
            {
                validationErrors.push("Du behöver mata in ett namn.");
            }
            if(!this.newBookingTime.email  == null || this.newBookingTime.email == '')
            {
                validationErrors.push("Du behöver mata in en email.");
            }
            if(!this.newBookingTime.agree_to_terms)
            {
                validationErrors.push("Du behöver bekräfta att du tagit del av informationen som berör bokningen.");
            }
            
            if(validationErrors.length > 0)
            {
                message.setValidationErrorMessage(validationErrors);
                return;
            }


            var self = this;
            this.isSending = true;
            this.$communicator.post('add-public-booking/', this.newBookingTime)
                .then(function(data)
                {                                                         
                    self.getBooking(); 
                    self.isSending = false;                           
                    self.showConfirmation = true;                           
                })
                .fail(function(error) {      
                    if(error.responseJSON.detail == 'already booked')
                    {
                        message.setValidationErrorMessage(["Du har redan en aktiv bokning registerad. Du kan endast ha en bokning registrerad i taget."]);  
                    }                   
                }) 
        },
        setNewStartTime()
        {
            this.newBookingTime.start_time = null;
            this.newBookingTime.end_time = null;
            this.hours.forEach(function(hour) {
                hour.selected = false;
                hour.isStartTime = false;
            });
        },
        getColor(location)
        {
            return niceFunctions.getColor(this.publicPageColors, location)
        } 
    },
    computed: {
        url() { return config.urlToServer },  
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        height() { return window.innerHeight/1.3 + 'px' },
        customerName() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.customerName : {} },
        smsNotificationEnabled() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.smsNotificationEnabled : null },
        publicPageColors() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageColors : null },
    },
    watch: {
    "$route.params.bookingid": {
            handler: function(value) {
                this.getBooking();
            },
            deep: true,
            immediate: true,
        },     
        windowScroll(newvalue)
        {
            if( $("#BookingHoursAdd-calendar")[0] == undefined)
            {
                return false;
            }
            
            if(newvalue > $("#BookingHoursAdd-calendar")[0].offsetTop - window.innerHeight)
            {
                this.showBookingBar = true;
            }
            else
            {
                this.showBookingBar = false;
            }
        }
    
    }
}
</script>
<template>
<div class="public-page" v-if="bookingItem != null && publicPage != null" v-fade-in>
    <div class="top-public position-relative" :style="{ 'background-image': bookingItem.image_name != null ? 'url(' + url + 'uploads/'+ publicPage.customer_id+'/images/'+ bookingItem.image_name + ')' : '', 'height': height }">
        <SvgTransition :publicPage="publicPage"></SvgTransition>   
    </div>
    <div class="text-section" v-fade-in>
        <section>
            <button class="btn margin-top-15 float-right" v-if="newBookingTime.end_date != null" @click="showSummary = true">Visa sammanfattning och slutför bokningen</button>
            <h1 :style="{ 'color': getColor('header_color_on_white_bg') }">Skapa bokning av {{ bookingItem.name }}</h1>
            <div class="margin-top-15" v-html="this.$adaptHtml.adaptHtml(bookingItem.information, getColor('header_color_on_white_bg'))"></div>
            <div class="info BookingAdd-info-box" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(90%)' }">
                <b style="text-transform: uppercase;">Läs igenom följande information innan du bokar:</b>
                <div class="margin-top-15" v-html="this.$adaptHtml.adaptHtml(bookingItem.rules, getColor('header_color_on_white_bg'))"></div>
                <p>Max bokningtid: {{ bookingItem.duration_time }} timmar</p>
            </div>
            <div class="BookingDaysAdd-booking-times" v-if="showBookingBar" v-fade-in :style="{ 'background-color': getColor('background_color') }">
                <div>
                    1. Välj datum 
                    <h2> {{ newBookingTime.selected_date  }} </h2>   
                </div>
                <div>
                    <span :style="{ 'opacity': newBookingTime.selected_date == null ? '0.3' : '1', 'margin': '0px'}">2. Välj Starttid</span>
                    <h2>{{newBookingTime.start_time }} <button class="btn btn-gray" v-if="newBookingTime.start_time != null" @click="setNewStartTime">Ändra starttid</button></h2>
                </div>
                <div>
                    <p :style="{ 'opacity': newBookingTime.start_time == null ? '0.3' : '1', 'margin': '0px'}" class="margin-top-15">
                        3. Välj Sluttid 
                    </p>
                    <h2>{{newBookingTime.end_time }}</h2>
                </div>
                <div class="pc-only">
                    <button class="btn margin-top-15 BookingHoursAdd-confirm-btn" :style="{ 'background-color': getColor('background_color') + ' !important' }" v-if="newBookingTime.end_time != null" @click="showSummary = true">Ange kontaktuppgifter och slutför bokningen</button>                           
                </div>
                <button class="btn mobile-only margin-top-15 BookingHoursAdd-confirm-btn" :style="{ 'background-color': getColor('background_color') + ' !important' }" v-if="newBookingTime.end_time != null" @click="showSummary = true">Ange kontaktuppgifter och slutför bokningen</button>                           
                  
            </div>
            <div class="g-60" id="BookingHoursAdd-calendar">
                <div style="padding: 0px">
                    <div class="row">
                        <div class="width33 month-link" @click="showPrevMonth">&lt; {{ currentDate.getMonth() == 0 ? 'december' : months[currentDate.getMonth()-1] }}  </div>
                        <div class="width33 month">{{ this.month }} {{ this.currentDate != null ? this.currentDate.getFullYear() : '' }}</div>
                        <div class="width33 text-right month-link" @click="showNextMonth">{{ months[currentDate.getMonth()+1] }} &gt;</div>
                    </div>
                    <div class="row days">
                        <div v-for="day in days" class="width14">
                        {{ day }}               
                        </div>
                    </div>   
                    <div v-for="(day,index) in allDaysInMonth" class="width14 float-left dates" :style="{ 'border-left': index % 7 == 0 ? '1px dashed #ddd' : '' }" :class="{'BookingAdd-today-date': day.isToday, 'wow-color': day.selected }" @click="selectDate(day)">
                        <div class="day-content">
                            <i :style="{'opacity': day.isCurrentMonth ? '1' : '0.4', 'color': day.isSunday ? 'red': 'black' }">{{ day.isToday ? day.day + ' (idag)' : day.day }}</i>  
                            <div v-if="allBookingTimes != null">                 
                                <p class="BookingHoursAdd-booked-period" v-for="booking in allBookingTimes.filter(x => day.date != undefined && x.start_date.split('T')[0] == day.dateString).slice(0, 3)">                    
                                        <i v-if="!this.$isMobile" class="fa-regular fa-clock"></i> <span v-date="{date: booking.start_date, type: 'time'}"></span>-<span v-date="{date: booking.end_date, type: 'time'}"></span>                          
                                </p>
                                <p v-if="allBookingTimes.filter(x => day.date != undefined && x.start_date.split('T')[0] == day.dateString).length > 3">
                                    {{ allBookingTimes.filter(x => day.date != undefined && x.start_date.split('T')[0] == day.dateString).length -3 }} bokningar till
                                </p>
                            </div>
                        </div>             
                    </div>  
                </div>
            </div>  
            <div class="g-40" v-show="hours.length > 0" id="BookingHoursAdd-times">
                <div class="BookingAdd-hour-content">                
                    <div v-for="hour in hours" class="BookingAdd-hour" @mouseover="hour.hover = true" @mouseleave="hour.hover = false" @click="selectTime(hour)" :style="{ 'background-color': hour.selectable && hour.hover ? '#ddd' : 'white', 'cursor': hour.selectable ? 'pointer': 'auto'  }">                                            
                        <div class="BookingAdd-hour-text">{{hour.hour }}</div>                  
                        <div class="BookingAdd-booking-selected" v-if="hour.selected == true" :style="{ 'height': 30 + 'px', 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(90%)' }">
                            <span style="margin: 5px 9px; display: block;" v-if="hour.isStartTime">{{ newBookingTime.start_time }} - {{ newBookingTime.end_time }}</span>
                        </div>
                        <div class="BookingAdd-booking-occupied" v-if="hour.booking != undefined" :style="{ 'height': hour.booking.duration_time * 30 + 'px' }">
                            <p><span v-date="{date: hour.booking.start_date, type: 'time'}"></span>-<span v-date="{date: hour.booking.end_date, type: 'time'}"></span></p>
                        </div>                      
                    </div>
                </div>             
            </div>   
        </section>
        <div v-if="showSummary && !showConfirmation">
            <PopUp>
                <h2>Bokning av {{ bookingItem.name }} | {{ newBookingTime.selected_date }} {{ newBookingTime.start_time }} - {{ newBookingTime.selected_date }} {{ newBookingTime.end_time }}</h2>
                <label>Ditt namn<span style="font-family: 'Courier New', Courier, monospace;">*</span></label>
                <input type="text" v-model="newBookingTime.full_name" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(120%)', 'border': '0px', }" />
                <div class="row-form">
                    <div class="g-50" :class="smsNotificationEnabled ? 'g-50' : 'g-100'"> 
                        <label>Din email (Hit skickas ett bekräftelsemail)<span style="font-family: 'Courier New', Courier, monospace;">*</span></label>
                        <input type="text" v-model="newBookingTime.email" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(120%)', 'border': '0px' }" />
                    </div>            
                    <div class="g-50" v-if="smsNotificationEnabled">
                        <label>Ditt mobilnummer</label>
                        <input type="text" v-model="newBookingTime.mobileNo" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(120%)', 'border': '0px' }"/>
                    </div>              
                </div>
                <div class="row-form margin-bottom-15" v-if="smsNotificationEnabled && newBookingTime.mobileNo != null && newBookingTime.mobileNo != ''" v-fade-in>
                    <div style="overflow:hidden">
                        <label style="margin-top: 15px;">Skicka även bokningsbekräftelse och påminnelse till valt mobilnummer</label>
                        <Switch :model="newBookingTime" :prop="'send_reminder_to_mobile_no'"></Switch>
                    </div>
                </div>
                <div class="row-form">
                    <label>Lägg till en kommentar (frivillig, kommer bara synas för dig)</label>
                    <textarea v-model="newBookingTime.comments" class="margin-bottom-15" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(120%)' }"></textarea>
                </div>
                <div class="info BookingAdd-info-box" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(90%)' }">                    
                    <Switch :model="newBookingTime" :prop="'agree_to_terms'"></Switch>
                    <b style="text-transform: uppercase;">&nbsp; Jag bekräftar att jag tagit del av informationen som berör bokningen</b>
                </div>
                <div class="margin-top-15 float-right">
                    <button class="btn btn-gray" style="padding: 10px"  @click="showSummary = false">Gå tillbaka och ändra i bokningen</button>                   
                    <button class="btn" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(90%)','padding': '10px' }"  @click="save">Slutför bokningen</button>
                </div>
            </PopUp>
        </div>
        <div v-if="showConfirmation">
            <PopUp :width="this.$isMobile ? '90%' : '600px'" :top="'60px'">
                <div style="text-align: center;">
                    <h2>Härligt! Bokningen av {{ bookingItem.name }} mellan {{ newBookingTime.selected_date }} {{ newBookingTime.start_time }} - {{ newBookingTime.selected_date }} {{ newBookingTime.end_time }} lyckades!</h2> 
                    Inom kort får du ett bekräftelsmail skickat till {{ newBookingTime.email }} (Håll utkik i skräpkorgen ifall den olyckligtvis hamnat där). <br><span style="font-size: 51pt; display: block">&#127870;</span> <br><strong>Vi ses snart! </strong> <br>   

                    <button class="btn" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(90%)','padding': '10px', 'margin': '30px 0px' }" @click="closeConfirm"> Tillbaka till startsidan</button>
                </div>
            </PopUp>
        </div>
    <div v-if="isSending">
            <PopUp :width="this.$isMobile ? '90%' : '600px'" :top="'60px'">
                <div style="text-align: center;">
                   <span style="font-size: 70px;"> &#x1F680;</span><br>
                    Genomför bokningen . . .
                </div>
            </PopUp>
        </div>
    </div>
</div>
</template>
<style scoped>
    section{
        width: 100%;
        overflow: auto;
        padding-bottom: 150px;
    }

    .row-form{
        width:calc(100% - 10px);
        overflow: auto;
    }

    .BookingDaysAdd-booking-times
    {
        padding:14px 10px 25px 10px;
        margin-top:30px; 
        border-radius:5px; 
        text-align: center;
        overflow: auto;
        position: fixed;
        bottom: 0px;
        width: calc(100% - 20px);
        left: 0px;
        z-index: 100;
    }

  
    .BookingDaysAdd-booking-times div{
        float: left;
        width: 25%;
        height: 100px;
    }
    .BookingHoursAdd-confirm-btn {
        border: 2px solid black;
        border-radius: 5px;
        filter: brightness(90%);
        padding: 10px;
        font-weight: bold !important;
    }
    
    .BookingHoursAdd-confirm-btn:hover {      
        filter: brightness(70%);     
    }

    .day-content
    {
        margin: 0px 5px 0px 5px;
        height: 120px;
        
    }
    
    .day-content:hover
    {
       
       cursor: pointer;
      
    }

    .day-content p
    {
        font-size: 12px;
        background-color: rgb(245, 175, 175);
        padding: 1px 5px;
        margin: 1px;
        border-radius: 4px;
    }
    
    .row div{
        padding:10px 0px;
    }
    
    .dates {
        padding:4px 0px;
        border-right: 1px dashed #ddd;
        background-color: white;
        border-bottom: 1px dashed #ddd;

    }
    
    .month{
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }

    .month-link
    {
        cursor: pointer;
    }
    
    .days{
        text-align: center;
        font-weight: bold;
        background-color: white;
    } 
    
    .days div{
        border-right: 1px dashed #ddd;
        border-top: 1px dashed #ddd;
        border-bottom: 1px dashed #ddd;
        
    }

    .days div:first-child{
        border-left: 1px dashed #ddd;
    }
    .row div{
        float: left;
    }

    .width14
    {
        width: calc(14.2% - 2px);
    }

    .BookingAdd-hour
    {
        border-top: 1px dashed #ddd;
        height: 30px;
        position: relative;
    }
    
    .BookingAdd-hour-content
    {
        margin-top:56px; 
    }

    .BookingAdd-today-date
    {
        background-color: #ddd;
    }
    .BookingAdd-booking-occupied
    {
        background-color: rgb(245, 175, 175);
        width: 80%;
        height: 30px;
        float: left;
        margin-left: 100px;
        position: absolute;
        top: 0px;
        z-index: 1;
        border-bottom: 1px solid red;
    }

    .BookingAdd-booking-selected
    {
        background-color: rgb(243, 235, 161);
        width: 80%;
        height: 30px;
        float: left;
        margin-left: 100px;
        position: absolute;
        top: 0px;
        z-index: 1;
        border-bottom: 1px solid  rgb(243, 235, 161);
    }

    .BookingAdd-booking-occupied p
    {
        margin: 10px;
    }

    .BookingAdd-hour-text
    {
        margin-top: -11px;
        width:45px;
        padding-bottom: 12px;
        background-color: white;
    }

    .BookingAdd-info-box
    {
        padding:25px;
        margin-bottom: 15px;
        margin-top: 23px; 
    }
   
   textarea
   {
    height: 100px;
    width: calc(100% - 30px);
    border: 0px;
    padding: 10px;
   }

   label{
    margin-bottom: 4px;  
    }

@media only screen and (max-width: 900px) {
    .BookingDaysAdd-booking-times {
        height: 130px;

    }
    .BookingDaysAdd-booking-times div
    {
        width: 33%;
    }

    .BookingHoursAdd-confirm-btn
    {
        width: calc(100% - 10px);
    }

    .BookingHoursAdd-booked-period
    {
        line-height: 15px;
        font-size: 11px !important;
    }

}

</style>