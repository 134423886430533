<script>
import config from "@/helpers/config"
import niceFunctions from "@/helpers/niceFunctions"
import SvgTransition from '@/components/SvgTransition.vue'
export default {
    components: {SvgTransition },
    created() {  
        this.$cache.createCache('publicPage', 'public-page/', {id: this.$route.params.id });      
       
    },
    methods:
    {
        getColor(location)
        {
            return niceFunctions.getColor(this.publicPageColors, location)
        }  
    },
    computed: {
        url() { return config.urlToServer },  
        publicPageColors() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageColors : null },
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        publicPageImages() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageImages : null },
        height() { return window.innerHeight/1.3 + 'px' }
  }
}
</script>
<template>
   <section  class="public-page" v-if="publicPage != null" v-fade-in>
        <div class="top-public position-relative" :style="{ 'height': height }" v-background="publicPageImages.find(x => x.location == 'contact')">       
            <SvgTransition :publicPage="publicPage" :color="getColor('background_color')"></SvgTransition>   
        </div>
        <div :style="{ 'background-color': getColor('background_color') }">
            <div class="text-section">
                <h1 :style="{ 'color': getColor('header_color') }">Kontakta oss</h1>
                <div v-html="this.$adaptHtml.adaptHtml(publicPage.contact_text, getColor('header_color'))"></div>
            </div>
        </div>
   </section>
</template>
<style scoped>

</style>